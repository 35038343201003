import React, { useState, useContext, useEffect } from "react";
import user from "../assets/user.svg";
import lock from "../assets/lock.svg";
import background from "../assets/background.png";
import thunder from "../assets/thunder.svg";
import { useNavigate } from "react-router-dom";
import PoweredBy from "../components/Common/PoweredBy.js";

import { AuthContext } from "../auth/AuthProvider";
import toast from "react-hot-toast";
import { Spinner } from "@chakra-ui/react";
import posLogo from "../assets/posLogo.png";

function Login() {
  const navigate = useNavigate();
  const { loginUser, loading, token } = useContext(AuthContext);
  const [error, setError] = useState("");
  const [loginData, setLoginData] = useState({
    userName: "",
    password: "",
  });
  useEffect(() => {
    if (token) navigate("/dashboard");
  });

  // if (loading) {
  //   return (
  //     <div className="flex items-center justify-center h-screen">
  //       <Spinner />
  //     </div>
  //   );
  // }

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!loginData.userName || !loginData.password) {
      setError("Username and password are required")
      return;
    }

    try {
      toast.loading("Please wait...");
      await loginUser(loginData.userName, loginData.password);
      toast.dismiss();
      toast.success("login successful!");
      setTimeout(() => {
        navigate("/dashboard");
      }, 1);
    } catch (error) {
      toast.dismiss();
      if (error.response && error.response.status === 401) {
        setError("Login Password is incorrect. Please try again.");
      } else if (error.response && error.response.status === 404) {
        setError("Login Username is incorrect. Please try again.");
      } else {
        setError(error?.response?.data?.message || "An error occurred");
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData({
      ...loginData,
      [name]: value,
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleLogin(e);
    }
  };

  return (
    <div className="d-flex flex-col md:flex-row h-[100vh] w-[100vw] overflow-hidden ">
      <div className="d-flex flex-column align-items-center justify-center px-[1%] py-5 sm:px-[5%] md:px-0 h-full w-[100%] md:w-[55%]">
        <div className="w-full flex flex-col flex-grow-1 items-center justify-center">
          <div>
            <img src={posLogo} alt="CAMPPOS" className="w-32 h-32" />
          </div>
          <div className="d-flex  flex-column lg:justify-content-start  md:mt-[10px] w-[85%] md:w-[70%] lg:w-[65%] xl:w-[40%]">
            <div
              className="d-flex justify-content-center align-items-center mb-[18px]"
              style={{
                fontSize: "30px",
                fontWeight: "700",
                lineHeight: "45px",
              }}
            >
              POS DASHBOARD
            </div>
            <div
              className="d-flex justify-center h-[24px] mb-[27px]"
              style={{
                fontWeight: "400",
                color: "#525252",
                fontSize: "14px",
              }}
            >
              Login with your information below
            </div>
            <div
              className="d-flex flex-row align-items-center mb-4 px-[18px]"
              style={{
                gap: "6px",
                width: "100%",
                height: "52px",
                borderRadius: "16px",
                background: "#E06F2C0F",
              }}
            >
              <span
                className="d-flex justify-content-center align-items-center mr-[6px]"
                style={{
                  width: "24px",
                  height: "24px",
                }}
              >
                <img src={user} alt="user" />
              </span>
              <span className="d-flex justify-content-center align-items-center w-[100%]">
                <input
                  autoFocus
                  type="text"
                  placeholder="Username"
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  id="userName"
                  name="userName"
                  value={loginData.userName}
                  size="18"
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    padding: "5px",
                    outline: "none",
                    width: "100%",
                  }}
                />
              </span>
            </div>
            <div
              className="d-flex flex-row align-items-center px-[18px]"
              style={{
                gap: "6px",
                width: "100%",
                height: "52px",
                borderRadius: "16px",
                background: "#E06F2C0F",
              }}
            >
              <span
                className="d-flex justify-content-center align-items-center mr-[6px]"
                style={{
                  width: "24px",
                  height: "24px",
                }}
              >
                <img src={lock} width="16px" height="21px" alt="lock icon" />
              </span>
              <span className="d-flex justify-content-center align-items-center w-[100%]">
                <input
                  type="password"
                  placeholder="Password"
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  id="password"
                  value={loginData.password}
                  name="password"
                  size="18"
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    padding: "5px",
                    outline: "none",
                    width: "100%",
                  }}
                />
              </span>
            </div>
            {error && (
              <div className="flex justify-center items-center mt-[12px]">
                <div
                  className="flex flex-row justify-center items-center"
                  style={{
                    gap: "4px",
                    color: "#D22727",
                    fontWeight: "400",
                    fontSize: "11px",
                    lineHeight: "16.5px",
                  }}
                >
                  {error}
                </div>
              </div>
            )}
            <div className="d-flex flex-row justify-content-center mt-3">
              <button
                onClick={handleLogin}
                style={{
                  width: "124px",
                  height: "52px",
                  borderRadius: "16px",
                  background:
                    "linear-gradient(99.78deg, #E06F2C -5.85%, #FF9F66 109.55%)",
                  boxShadow: "0px 8px 21px 0px #00000029",
                  border: "0",
                  fontWeight: 700,
                  size: "12px",
                  color: "#FFFFFF",
                }}
              >
                Login now
              </button>
            </div>
          </div>
        </div>

        <div>
          <PoweredBy />
        </div>
      </div>

      <div className="relative hidden md:inline w-[45vw] h-100">
        <img
          src={background}
          alt="gradient view"
          style={{ height: "100%", width: "100%" }}
        />

        <div className="absolute d-flex justify-center items-center top-[0px] left-[0px] h-[100vh] w-[100%]">
          <div
            className="relative text-white w-[85%] h-[35%] md:w-[82%] md:h-[35%] lg:w-[80%] lg:h-[40%] xl:w-[60%] xl:h-[45%] 2xl:w-[50%] 2xl:h-[50%] d-flex flex-col justify-center items-center"
            style={{
              background: "#FFFFFF36",
              border: "1px solid #FFFFFF85",
              borderRadius: "46px",
            }}
          >
            <div className="absolute w-[80%] h-[80%] md:w-[98%] d-flex items-center justify-center font-bold text-[24px] lg:text-[36px]">
              Swift <br /> transactions,
              <br /> seamless <br /> experience.
              <br /> Fast, quick, <br /> and easy.
            </div>

            <div
              className=" d-flex justify-center items-center absolute  h-[79px] w-[79px] bg-white"
              style={{ bottom: "-25px", borderRadius: "50%", left: "-30px" }}
            >
              <img src={thunder} alt="thunder" />
            </div>
          </div>
        </div>
      </div>

      <style>{`
        input::placeholder {
          color: #1c1c1c;
          font-size: 14px;
          opacity: 1;
          transition: color 0.3s ease;
        }

      

      `}</style>
    </div>
  );
}

export default Login;


