import React, { useState, useContext, useEffect } from "react";
import { Switch } from "antd";
import { AuthContext } from "../../../auth/AuthProvider";
import FloatingInputField from "../FloatingInputField";
import sola from "../../../assets/sola.png";
import { db } from "../../../db/firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import ShimmerLoader from "../../loader/ShimmerLoader";

function OnlinePaymentsSetting() {
  const { user } = useContext(AuthContext);

  const [settings, setSettings] = useState({
    enableOnlinePayments: false,
    sendEReceipt: true,
    applyTransactionFee: true,
    showBalance: true,
    showTotalSpent: true,
  });

  const [contactInfo, setContactInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });

  const [hasValidKeys, setHasValidKeys] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const fetchOnlinePayments = async () => {
    try {
      setIsLoading(true);
      if (!user?.id) {
        console.error("User ID is missing");
        return;
      }

      const userDocRef = doc(db, "users", user.id);
      const userDocSnap = await getDoc(userDocRef);

      const userData = userDocSnap.data();

      sessionStorage.setItem("user", JSON.stringify(userData));

      setSettings({
        enableOnlinePayments: userData?.settings?.enableOnlinePayments || false,
        sendEReceipt: userData?.settings?.sendEReceipt || true,
        applyTransactionFee: userData?.settings?.applyTransactionFee || true,
        showBalance: userData?.settings?.showBalance || true,
        showTotalSpent: userData?.settings?.showTotalSpent || true,
      });

      setContactInfo({
        firstName: userData?.contactInfo?.firstName || "",
        lastName: userData?.contactInfo?.lastName || "",
        email: userData?.contactInfo?.email || "",
        phone: userData?.contactInfo?.phone || "",
      });

      const hasKeys = Boolean(userData?.xKey && userData?.iFields);
      setHasValidKeys(hasKeys);

      if (!hasKeys) {
        setSettings((prev) => ({
          ...prev,
          enableOnlinePayments: false,
        }));
        updateFirebase({
          settings: {
            ...userData?.settings,
            enableOnlinePayments: false,
          },
        });
      }
    } catch (error) {
      console.error("Error fetching online payments", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (user?.id) {
      fetchOnlinePayments();
    }
  }, [user]);

  const updateFirebase = async (data) => {
    try {
      if (!user?.id) {
        console.error("User ID is missing for updateFirebase");
        return;
      }

      const userDoc = doc(db, "users", user.id);
      await updateDoc(userDoc, data);
    } catch (error) {
      console.error("Error updating Firebase", error);
    }
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;

    const updatedContactInfo = {
      ...contactInfo,
      [id]: value,
    };

    setContactInfo(updatedContactInfo);

    const trimmedContactInfo = {
      ...contactInfo,
      [id]: value.trim(),
    };

    updateFirebase({
      contactInfo: trimmedContactInfo,
    });
  };

  const handleSettingChange = (settingKey) => (checked) => {
    if (settingKey === "enableOnlinePayments" && !hasValidKeys) return;

    const updatedSettings = {
      ...settings,
      [settingKey]: checked,
    };

    setSettings(updatedSettings);

    updateFirebase({
      settings: updatedSettings,
    });
  };

  const getSwitchProps = (settingKey) => ({
    className: `${settings[settingKey] ? "" : "custom-switch"}`,
    onChange: handleSettingChange(settingKey),
    checked: settings[settingKey],
    disabled: settingKey === "enableOnlinePayments" && !hasValidKeys,
    style: {
      backgroundColor: settings[settingKey]
        ? "rgba(224, 111, 44, 1)"
        : "rgba(248, 248, 248, 1)",
    },
  });

  return (
    <div className="flex flex-col items-center px-[55px] py-[30px] w-full h-full">
      {isLoading ? (
        <div className="w-full">
          <ShimmerLoader />
          <div className="w-[60%] my-[30px]">
            <ShimmerLoader />
            <ShimmerLoader />
          </div>
          <ShimmerLoader />
          <ShimmerLoader />
          <ShimmerLoader />
          <ShimmerLoader />
        </div>
      ) : (
        <>
          <div className="flex items-center w-100">
            <div className="flex-grow-1 font-medium text-[18px] leading-[32px]">
              Enable this setting to allow parents to add Money to Campers
              account online.
            </div>
            <div>
              <Switch {...getSwitchProps("enableOnlinePayments")} />
            </div>
          </div>

          {settings.enableOnlinePayments && hasValidKeys && (
            <div className="flex flex-col gap-3 mt-[29px] w-full">
              <hr
                style={{
                  border: "1px solid #EAEAEA",
                  width: "100%",
                  margin: "0",
                }}
              />
              <div className="font-medium text-[18px] leading-[32px] text-[#000000]">
                {" "}
                Contact Info{" "}
              </div>
              <div className="grid grid-cols-2 gap-4 mt-2 xl:w-[650px] mb-[21px]">
                <FloatingInputField
                  id="firstName"
                  label="First Name"
                  value={contactInfo.firstName}
                  onChange={handleInputChange}
                />

                <FloatingInputField
                  id="lastName"
                  label="Last Name"
                  value={contactInfo.lastName}
                  onChange={handleInputChange}
                />
                <FloatingInputField
                  type="number"
                  id="phone"
                  label="Phone Number"
                  value={contactInfo.phone}
                  onChange={handleInputChange}
                />
                <FloatingInputField
                  id="email"
                  label="Email"
                  value={contactInfo.email}
                  onChange={handleInputChange}
                />
              </div>

              <div className="flex flex-col gap-[21px] text-[17px] font-medium leading-[32px] text-[#000000]">
                <hr
                  style={{
                    border: "1px solid #EAEAEA",
                    width: "100%",
                    margin: "0",
                  }}
                />

                <div className="flex items-center">
                  <div className="flex-grow-1 text-[20px] font-[500]">
                    Send Automatic E-Receipt After Order
                  </div>
                  <div>
                    <Switch {...getSwitchProps("sendEReceipt")} />
                  </div>
                </div>

                <hr
                  style={{
                    border: "1px solid #EAEAEA",
                    width: "100%",
                    margin: "0",
                  }}
                />

                <div className="flex items-center">
                  <div className="flex-grow-1 text-[20px] font-[500]">
                    Apply 3% Transaction Fee to Each Order
                  </div>
                  <div>
                    <Switch {...getSwitchProps("applyTransactionFee")} />
                  </div>
                </div>

                <hr
                  style={{
                    border: "1px solid #EAEAEA",
                    width: "100%",
                    margin: "0",
                  }}
                />

                <div className="flex items-center">
                  <div className="flex-grow-1 text-[20px] font-[500]">
                    Show Child's Current Balance on Account
                  </div>
                  <div>
                    <Switch {...getSwitchProps("showBalance")} />
                  </div>
                </div>

                <hr
                  style={{
                    border: "1px solid #EAEAEA",
                    width: "100%",
                    margin: "0",
                  }}
                />

                <div className="flex items-center">
                  <div className="flex-grow-1 text-[20px] font-[500]">
                    Show Total Amount Spent by Child
                  </div>
                  <div>
                    <Switch {...getSwitchProps("showTotalSpent")} />
                  </div>
                </div>
              </div>

              <div className="px-8 py-6 mt-2 flex flex-col gap-[23px] items-center justify-center">
                <div className="font-normal text-[15px] leading-[19.5px] text-center">
                  Payment Partner
                </div>

                <img src={sola} alt="sola" className="w-[191px] h-[52.8px]" />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default OnlinePaymentsSetting;
