import React from "react";
import "./ShimmerLoader.css";

const ShimmerLoader = () => {
  return (
    <div className="group">
      <div className="list">
        <div className="image">
          <span className="loader-element"></span>
        </div>
        {/* <div className="meta">
          <h3 className="title">
            <span className="loader-element"></span>
          </h3>
          <div className="intro">
            <span className="loader-element"></span>
            <span className="loader-element"></span>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default ShimmerLoader;
